import './obligationSummary.scss';

import {
    IObligations,
    SavedObligationData,
    StatusChose,
    UserRole,
    IActionSummary,
} from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    addNotificationData,
    getSavedObligation,
    saveAnalystObligations,
    saveReviewerObligations,
    setPaginationExternally,
    storeEditSumText,
    updateEditSumText,
    updateStatusInObligationsToSave,
    updateTooltipContent,
} from 'app/views/home/redux/homeActions';
import {
    getEditSumText,
    retrieveHashValue,
    selectNotificationsData,
    selectObligation,
} from '../../views/home/redux/homeSelectors';
import { headerAnalyst, headerReviewer } from './tableHeader';
import { limit, percentage } from 'constants/constants';
import { retrieveAllPagesFile, selectObligationsToSave } from 'app/views/home/redux/homeSelectors';

import ApproveIcon from '../reusable/approveIcon/ApproveIcon';
import ChipComponent from '../nds/chip/chip';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import Edit24px from '@nexus/core/dist/assets/icons/editor/ic_mode_edit_24px.svg';
import ModalComponent from '../nds/modal/modal';
import { NexusIcon, NexusFormField, NexusInput, NexusChip } from '@nexus/react';
// import NoRecordsTemplate from 'app/components/reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import RejectIcon from '../reusable/rejectIcon/RejectIcon';
import TextareaComponent from '../nds/textarea/textarea';
import TitleBar from '../reusable/titleBar/TitleBar';
import TooltipComponent from '../nds/tooltip/tooltip';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import { getValuePercentage } from 'services/getPercentagevalue';
import i18n from 'locales/i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

const ObligationSummary: React.FC = () => {
    const userRole = useSelector(getUserRole);
    const notificationsData = useSelector(selectNotificationsData);
    const obligationsToSave = useSelector(selectObligationsToSave);
    const hash = useSelector(retrieveHashValue);
    const editedSumText = useSelector(getEditSumText);
    const fullObligationsData = useSelector(selectObligation);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [sumText, setSumText] = useState<string>('');
    const [obgName, setObgName] = useState<string>('');
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    // const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = useState<string>('bottom');
    const [isTextAreaDisabled, setIsTextAreaDisabled] = useState<boolean>(true);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [score, setScore] = useState<number>(0.0);
    const [initalInputData, setInitalInputData] = useState<IActionSummary[]>([]);
    const [inputData, setInputData] = useState<IActionSummary[]>([]);
    const [summaryExtract, setSummaryExtract] = useState<IActionSummary[]>([]);

    const allPagesFiles = useSelector(retrieveAllPagesFile);

    const selectedPage = (data: number) => {
        setPage(data);

        dispatch(setPaginationExternally(false));
    };

    const pagesVisited = () => {
        return (page - 1) * limit;
    };

    const handleMouseMove = (e: MouseEvent): void => {
        const remainingSpace = window.innerHeight - e.clientY;
        if (remainingSpace > 230) {
            setTooltipPosition('bottom');
        } else {
            setTooltipPosition('top');
        }
    };
    useEffect(() => {
        setPage(1);

        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    useEffect(() => {
        setTooltipPosition('bottom');
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const validateTableRows = () => {
        const option = userRole === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
        const rowsStatuses = obligationsToSave.map((obligation: IObligations) => obligation[option]);
        // const valid = rowsStatuses.every((status: string) => status !== '');
        const valid = rowsStatuses.some((status: string) => status !== '');

        return !valid;
    };

    const handleSave = () => {
        const body: SavedObligationData = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            citation: fullObligationsData.citation,
            comments: 'string',
            draftobg: obligationsToSave,
            filesize: 0,
            regulation_id: fullObligationsData.regulation_id,
            regulation_name: fullObligationsData.regulation_name,
            regulation_type: 'string',
            run_id: hash,
            source_url: fullObligationsData.source_url,
            status: 'string',
        };

        if (userRole !== UserRole.ANALYST) {
            /**
             * @Backend not ready before DEMO 28th October 2022
             * todo when new JAVA Backend has endpoint create ACTION.saveReviewerObligations
             * */
            dispatch(saveReviewerObligations(body));
            return;
        }

        // dispatch(saveAnalystObligations(body));
        dispatch(saveAnalystObligations());
    };

    const handleSubmit = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 3000,
                    variant: 'success',
                },
            ]),
        );
    };

    const handleOptionsChange = (value: StatusChose, name: string) => {
        dispatch(updateStatusInObligationsToSave({ name, status: value, user: userRole }));
    };

    const handleEditModal = (
        sumText: string,
        obgName: string,
        confidence: number,
        summary: IActionSummary[],
        summaryExtract: IActionSummary[],
    ) => {
        setShowModal(true);
        setSumText(sumText);
        setObgName(obgName);
        setScore(confidence);
        setInputData(summary);
        setInitalInputData(summary);
        setIsEditMode(false);
        setIsTextAreaDisabled(true);
        setSummaryExtract(summaryExtract);
        dispatch(storeEditSumText(''));
        //   setDisableConfirmBtn(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const editSumText = (event: React.FormEvent<HTMLNexusTextareaElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        dispatch(storeEditSumText(target.value));
        //   target && setDisableConfirmBtn(false);
    };

    const handleExtract = () => {
        dispatch(updateEditSumText({ sum_text: editedSumText, name: obgName }));
        if (summaryExtract.length > 0) {
            setInputData(summaryExtract);
            setInitalInputData(summaryExtract);
            dispatch(updateTooltipContent({ action_summary: summaryExtract, name: obgName }));
        }
        //    setShowModal(false);
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('obligationSummary.saveExtractMesssage'),
                    time: 3000,
                    variant: 'success',
                },
            ]),
        );
    };

    const handleTooltipContentSave = () => {
        dispatch(updateTooltipContent({ action_summary: inputData, name: obgName }));
        setShowModal(false);
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('obligationSummary.saveSubmitMesssage'),
                    time: 3000,
                    variant: 'success',
                },
            ]),
        );
    };

    const handleInputChange = (e: any, index: number, key: string) => {
        let copyInputData = JSON.parse(JSON.stringify(inputData));
        copyInputData[index][key] = e.target.value;
        setInputData(copyInputData);
    };

    const getTooltipContent = (content: IActionSummary[]) => {
        if (content.length > 0) {
            return ReactDOMServer.renderToStaticMarkup(
                <table className='nexus-table nexus-flex-1' data-testid='obligations-table'>
                    <thead>
                        <tr>
                            {Object.keys(content[0]).map((item, key) => (
                                <th key={key}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {content.map((item: any) => (
                            <tr>
                                {Object.keys(item).map((key) => (
                                    <td key={item[key]}>{item[key]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>,
            );
        } else {
            return '';
        }
    };

    const modalBody = (
        <>
            <div className='modal-sub-header'>
                <span>Rules ID: {obgName} </span>
                <span className='divider'>| </span>
                <b> Confidence Score </b>
                <NexusChip data-testid='nexus-single-upload-chip' className='chip'>
                    {score}%
                </NexusChip>
            </div>
            <div className='nexus-col-md-8 edit-popup'>
                <TextareaComponent
                    disabled={isTextAreaDisabled}
                    value={sumText}
                    onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) => editSumText(event)}
                />
                <div className='btn-container'>
                    <button className='nexus-btn-primary' onClick={() => setIsTextAreaDisabled(false)}>
                        Edit
                    </button>
                    <button
                        className='nexus-btn save-btn'
                        onClick={handleExtract}
                        disabled={editedSumText === sumText || editedSumText === ''}
                    >
                        Save & Extract
                    </button>
                </div>
                {inputData.length > 0 && (
                    <table className='nexus-table nexus-flex-1' data-testid='obligations-table'>
                        <thead>
                            <tr>
                                {Object.keys(inputData[0]).map((item) => (
                                    <th>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {inputData.map((item: any, index) => (
                                <tr>
                                    {Object.keys(item).map((key) => (
                                        <td>
                                            {isEditMode ? (
                                                <NexusFormField>
                                                    <NexusInput
                                                        value={item[key]}
                                                        onInput={(e: any) => handleInputChange(e, index, key)}
                                                    />
                                                </NexusFormField>
                                            ) : (
                                                <span>{item[key]}</span>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );

    return (
        <div data-testid='nexus-draft-obligation-summary' className='obligation-summary'>
            {/*obligationsToSave?.length ? (
                <div className='padding-label'>
                    {UploadedDocumentText} {fullObligationsData.regulation_id}
                </div>
            ) : (
                <></>
            )*/}
            {obligationsToSave?.length ? (
                <>
                    <TitleBar title={i18n.t('obligationSummary.rulesStatement')} />
                    <></>
                    <table className='nexus-table nexus-flex-1' data-testid='obligations-table'>
                        <thead>
                            <tr>
                                {(userRole === UserRole.ANALYST ? headerAnalyst : headerReviewer).map(
                                    ({ id, label }) => (
                                        <th key={id} data-testid={`column-header-${id}`}>
                                            {label}
                                        </th>
                                    ),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {obligationsToSave
                                ?.slice(pagesVisited(), pagesVisited() + limit)
                                .map(
                                    ({
                                        obligation_name,
                                        obligation_highlighted_text,
                                        obligation_summary_text,
                                        confidence,
                                        status,
                                        status_to_be_reviewed,
                                        action_summary,
                                        action_extract,
                                    }: IObligations) => {
                                        return (
                                            <tr data-testid='row-1' key={obligation_name}>
                                                <td
                                                    data-testid='obligation-summary-obligation-name'
                                                    className='row-width'
                                                >
                                                    {obligation_name}
                                                </td>
                                                <td className='row-width'>
                                                    <TooltipComponent
                                                        message={obligation_highlighted_text}
                                                        placement={tooltipPosition}
                                                        className='obligation-tooltip'
                                                    >
                                                        <span
                                                            data-testid='obligation-summary-highlighted-text'
                                                            className='ellipsis'
                                                        >
                                                            {obligation_highlighted_text}
                                                        </span>
                                                    </TooltipComponent>
                                                </td>
                                                <td className='row-width'>
                                                    <TooltipComponent
                                                        message={getTooltipContent(action_summary)}
                                                        placement={tooltipPosition}
                                                        className='obligation-tooltip obligation-table-tooltip'
                                                    >
                                                        <span
                                                            data-testid='obligation-summary-highlighted-text'
                                                            className='ellipsis'
                                                        >
                                                            {obligation_summary_text}
                                                        </span>
                                                    </TooltipComponent>
                                                    {/*<span
                                                        data-testid='obligation-summary-obligation-summary-text'
                                                        className='ellipsis'
                                                    >
                                                        {obligation_summary_text}
                                        </span>*/}
                                                </td>
                                                <td className='row-width'>
                                                    <button
                                                        className='edit-btn'
                                                        onClick={() =>
                                                            handleEditModal(
                                                                obligation_summary_text,
                                                                obligation_name,
                                                                confidence,
                                                                action_summary,
                                                                action_extract,
                                                            )
                                                        }
                                                    >
                                                        <NexusIcon
                                                            data-testid='nexus-icon-in-obligation-summary-table'
                                                            size='md'
                                                            src={Edit24px}
                                                        ></NexusIcon>
                                                    </button>
                                                </td>
                                                <td data-testid='obligation-summary-confidence' className='row-width'>
                                                    <ChipComponent
                                                        title={getValuePercentage(confidence) + percentage}
                                                        // title={getValuePercentage(Math.random() * 20 + 75) + percentage}
                                                        contentStyles='rcm-chip-content-style'
                                                    />
                                                </td>
                                                {userRole === UserRole.REVIEWER && (
                                                    <td className='row-width'>
                                                        <span data-testid='analyst_status'>
                                                            {status_to_be_reviewed}
                                                        </span>
                                                    </td>
                                                )}
                                                <td>
                                                    <div className='nexus-row'>
                                                        <ApproveIcon
                                                            status={
                                                                userRole === UserRole.REVIEWER
                                                                    ? status
                                                                    : status_to_be_reviewed
                                                            }
                                                            handleApproveIcon={() =>
                                                                handleOptionsChange(
                                                                    StatusChose.APPROVED,
                                                                    obligation_name,
                                                                )
                                                            }
                                                        />
                                                        <RejectIcon
                                                            status={
                                                                userRole === UserRole.REVIEWER
                                                                    ? status
                                                                    : status_to_be_reviewed
                                                            }
                                                            handleRejectIcon={() =>
                                                                handleOptionsChange(
                                                                    StatusChose.REJECTED,
                                                                    obligation_name,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                        </tbody>
                    </table>
                    <PaginationComponent
                        selectedPage={selectedPage}
                        offset={0}
                        limit={limit}
                        size={obligationsToSave.length}
                        page={page}
                    />

                    <ControlButtons submit={handleSubmit} save={handleSave} validate={validateTableRows()} />

                    {showModal && (
                        <ModalComponent
                            showModal={showModal}
                            closeModal={closeModal}
                            headerContent={i18n.t('approvalModal.header') + ''}
                            modalBodyContent={modalBody}
                            primaryBtnText={i18n.t('approvalModal.Edit')}
                            primaryBtnTestId='obligation-text-area-modal-confirm'
                            secondaryBtnText={i18n.t('approvalModal.Save & Submit')}
                            primaryBtnOnclickCallBack={() => setIsEditMode(true)}
                            secondaryBtnTestId='obligation-text-area-modal-cancel'
                            secondaryBtnOnclickCallBack={handleTooltipContentSave}
                            size='md'
                            disableSecondaryBtn={initalInputData === inputData}
                        />
                    )}
                </>
            ) : // <NoRecordsTemplate />
            null}
        </div>
    );
};

export default ObligationSummary;
